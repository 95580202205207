<template>
  <div class="row m-0">
    <div class="col-md-12 gutter-b">
      <div class="card card-custom card-fit card-border">
        <div class="card-header">
          <h3 class="card-title font-weight-bolder">
            {{ reiseterminObject.reiseterminkuerzel }}
          </h3>
        </div>
        <div class="card-body pt-0">
          <div class="row m-0">
            <div class="col-md-6">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2" v-t="'REISEN.COMMON.REISEKUERZEL'"></span>
                <span class="text-muted">{{ reiseterminObject.reise.reisekuerzel }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2" v-t="'REISEN.COMMON.TRAVELPERIOD'"></span>
                <span class="text-muted">{{ reisezeitraum }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <a
                  :href="'https://skr.de/+' + reiseterminObject.reise.reisekuerzel"
                  target="_blank"
                  class="mr-2"
                >
                  Reisewiki
                </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2" v-t="'REISEN.COMMON.COUNTRY'"></span>
                <span class="text-muted">
                  {{ $t('REISEN.COUNTRIES.' + reiseterminObject.reise.country) }}
                </span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2" v-t="'REISEN.COMMON.KONTINENT'"></span>
                <span class="text-muted">{{ $t('REISEN.REGIONS.' + reiseterminObject.reise.region) }}</span>
              </div>
            </div>
          </div>
          <!--<a href="" target="_blank" class="mr-2">Fluginformationen</a>-->
          <ReiseterminViewBesonderheitenBase
            v-if="reiseterminObject"
            :besonderheiten="reiseterminObject.besonderheiten"
            :reisekuerzel="reiseterminObject.reise.reisekuerzel"
            :startdatum="reiseterminObject.startdatum"
          ></ReiseterminViewBesonderheitenBase>

          <b-skeleton v-else width="100%" height="39px"></b-skeleton>
        </div>
      </div>
    </div>
    <div class="col-md-12 gutter-b">
      <div class="card card-custom card-fit card-border">
        <div class="card-header">
          <h3 class="card-title font-weight-bolder" v-t="'REISEN.COMMON.VORGAENGE'"></h3>
          <div class="card-toolbar"></div>
        </div>
        <div class="card-body">
          <VorgangTable
            :isLoading="isLoadingVorgaenge"
            :displayFieldsByKey="[
              'id',
              'reisezeitraum',
              'kunde',
              'zimmer',
              'npx',
              'agentur.id',
              'brutto',
              'stateSlot',
            ]"
            :items="vorgaenge"
            :reiseterminId="reiseterminObject.id"
            @update-single-item="onUpdateSingleItem"
          >
            <template #stateSlot="row">
              <VorgangStateWidget :state="row.item.blankState" />
            </template>
          </VorgangTable>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.more-btn {
  cursor: pointer;
}
table#vorgaengeTable > thead > tr > th {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>

<script>
import * as odataService from '@/core/common/services/odata.service';

import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import ReiseterminViewBesonderheitenBase from '@/components/produkte/reisetermine/besonderheiten-base.vue';

const moment = require('moment');

export default {
  name: 'ReiseterminTableDetails',
  components: { VorgangTable, VorgangStateWidget, ReiseterminViewBesonderheitenBase },
  props: {
    reiseterminObject: { type: Object },
  },
  data() {
    return {
      vorgaenge: [],
      isLoadingVorgaenge: true,
      expand: {
        hotelleistungen: {
          filter: {
            isDeleted: false,
          },
          expand: {
            Reisetermin: {
              filter: {
                isDeleted: false,
              },
              expand: { Reise: {}, pax: {}, gueltigeKonfiguration: {} },
            },
          },
        },
        agentur: { select: ['id'] },
        kunde: {
          expand: {
            master: {
              expand: { kunden: {} },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    reisezeitraum() {
      if (!this.reiseterminObject) return '-';
      const reisedauer = this.reiseterminObject?.reisedauer ? this.reiseterminObject?.reisedauer - 1 : 0;
      const momentUntilDate = moment(this.reiseterminObject.abreisedatum).add(reisedauer, 'days');
      return this.reiseterminObject?.abreisedatum
        ? `${this.$options.filters.date(this.reiseterminObject.abreisedatum)} -  ${this.$options.filters.date(
            momentUntilDate
          )}`
        : '-';
    },
  },
  methods: {
    getData() {
      this.getVorgaenge(this.reiseterminObject.id);
    },
    getVorgaenge(id) {
      const filter = {
        hotelleistungen: {
          any: {
            isDeleted: false,
            reiseterminId: parseInt(id),
          },
        },
      };
      return odataService
        .getVorgang({ expand: this.expand, filter })
        .then(response => {
          this.vorgaenge = response.data;
          this.isLoadingVorgaenge = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async onUpdateSingleItem(index, id) {
      const newVorgang = await this.getVorgangById(id);
      this.$set(this.vorgaenge, index, newVorgang);
    },
    async getVorgangById(id) {
      this.isLoading = true;
      const filter = { id: id };
      return odataService
        .getVorgang({ expand: this.expand, filter })
        .then(response => {
          return response.data[0];
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <FilterOverview
      :title="'Reiseterminübersicht'"
      :isInitiallyLoading="isInitiallyLoading"
      @reload="onReload"
      :showFilterButton="true"
    >
      <template #title-right>
        <b-button
          class="btn-xs btn p-2 ml-2 justify-content-center align-item-center d-flex"
          v-b-tooltip.hover
          :title="titleHoverText"
        >
          <i class="fas fa-info m-0 p-0"></i>
        </b-button>
      </template>
      <template #toolbar-left>
        <ReiseterminTableAggregationBarBase
          :filter="$store.getters['overview/appliedReiseterminFilterArray']"
        ></ReiseterminTableAggregationBarBase>
      </template>
      <template #filter>
        <ReiseterminFilter filterNamespace="overview"></ReiseterminFilter>
      </template>
      <template #table="{ tableHeight, heightComponentInlineStyle }">
        <ReiseterminTable
          :filterMessage="filterMessage"
          :items="reisetermine"
          :isBusy="isBusy"
          :count="count"
          :tableHeight="tableHeight"
          :heightComponentInlineStyle="heightComponentInlineStyle"
          :hideFieldsByKey="['reisedauer']"
          @update-single-item="updateSingleItem"
          @pageChange="onPageChange"
          @sortChange="onSortChange"
        ></ReiseterminTable>
      </template>
    </FilterOverview>
  </div>
</template>

<style lang="scss" scoped>
.card-filter-nav {
  overflow-y: auto;
}
</style>
<script>
import {
  GET_REISETERMINE,
  REISETERMINE_SORT_CHANGED,
  REISETERMINE_PAGE_CHANGED,
  UPDATE_REISETERMIN_BY_ID,
} from '@/core/produkte/reisetermine/stores/reisetermine.module';

import { mapGetters, mapState } from 'vuex';

import ReiseterminTable from '@/components/produkte/reisetermine/reisetermin-table';
import ReiseterminTableAggregationBarBase from '@/components/produkte/reisetermine/reisetermin-table-aggregation-bar-base';

import ReiseterminFilter from '@/components/produkte/reisetermine/reisetermin-filter.vue';
import FilterOverview from '@/components/common/filter-overview.vue';

export default {
  name: 'ReisetermineOverviewComponent',
  components: {
    ReiseterminTable,
    ReiseterminTableAggregationBarBase,
    ReiseterminFilter,
    FilterOverview,
  },
  data() {
    return {
      titleHoverText:
        'In der Reiseterminübersicht werden alle Termine gelistet, die in Blank angelegt sind. ',
    };
  },
  mounted() {
    this.$store.dispatch('overview/' + GET_REISETERMINE);
  },
  computed: {
    ...mapState('overview', ['isBusy', 'reisetermine', 'count', 'isInitiallyLoading']),
    ...mapGetters('overview', ['filterMessage']),
  },
  methods: {
    onReload() {
      this.$store.dispatch('overview/' + GET_REISETERMINE);
    },
    async updateSingleItem(itemIndex, id) {
      return await this.$store.dispatch('overview/' + UPDATE_REISETERMIN_BY_ID, id);
    },
    onSortChange(ctx) {
      this.$store.dispatch('overview/' + REISETERMINE_SORT_CHANGED, { sortContext: ctx });
    },
    onPageChange(ctx) {
      this.$store.dispatch('overview/' + REISETERMINE_PAGE_CHANGED, { pageContext: ctx });
    },
  },
};
</script>
